import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import caso3 from "../../static/images/exito3.jpg"
import chetumal1 from "../../static/images/chetumal1.jpg"

const CasosExito = () => (
  <Layout noNav={true}>
    <SEO title="Casos de éxito" />

    <div className="shape-container my-5">
      <div className="shape-top" />
      <div className="shape-left" />
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-md-4">
            <h1 className="text-blue">Caso de Éxito</h1>
            <h3 className="text-blue">
              Policlínica Sistema de Salud de Nuestra Señora del Carmen en CDMX
            </h3>
            <p>
              Este proyecto estuvo conformado por siete Instalaciones de
              Telecomunicaciones y Seguridad, es emblemático no sólo por el
              diseño propio de las Instalaciones, sino por el seguimiento de la
              ejecución en obra hasta su implementación.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="shape-container-2">
      <div className="shape-only-left" />
      <div className="shape-over-left" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 offset-md-6">
            <h1 className="text-blue">Caso de Éxito</h1>
            <h3 className="text-blue">
              Reestructuración del sistema de Circuito Cerrado de Televisión y
              del sistema de Detección de Incendios
            </h3>
          </div>
          <div className="col-lg-8 offset-lg-4 pt-5">
            <h4 className="text-blue mt-4">
              Edificio Corporativo Cooperativa la Cruz Azul CDMX
            </h4>
            <p>
              Se realizó el levantamiento del estado actual de todo el sistema
              de Detección de Incendios y del Circuito Cerrado de Televisión del
              Corporativo, emitiendo además de los planos de levantamiento, un
              dictamen técnico de la tecnología en su estado actual; lo cuál
              permitió desarrollar los proyectos ejecutivos de actualización
              tecnológica de estos dos sistemas, así como el seguimiento durante
              su instalación y puesta en marcha, integrándolos a su vez a la
              construcción de un proyecto nombrado "Aulas Corporativas" que
              estaba en ejecución al mismo tiempo.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="shape-container-3">
      <div className="shape-short-left" />
      <div className="shape-short-right" />
    </div>

    <div className="container pt-5">
      <div className="row">
        <div className="col-lg-4">
          <div className="col-lg-12">
            <img src={chetumal1} alt="" className="img-fluid mb-4" />
          </div>
          <div className="col-lg-12">
            <img src={caso3} alt="" className="img-fluid mb-4" />
          </div>
        </div>
        <div className="col-lg-8 my-auto">
          <h1 className="text-blue">Caso de Éxito</h1>
          <h3 className="text-blue">
            Aeropuerto Internacional <br /> de Chetumal
          </h3>
          <p>
            Se realizaron los diseños de Telecomunicaciones y Seguridad para
            tres intervenciones en el Aeropuerto.
          </p>
          <p>
            Nueva Torre de Control, Ampliación del Edificio Terminal y
            Remodelación del Edificio Terminal existente; estas acciones se
            llevaron a cabo derivadas de la mejora propuesta por el Gobierno
            Federal referente a la infraestructura en transporte para la zona.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default CasosExito
